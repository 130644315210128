.wrapper {
  display: revert;
  margin: 2rem 0rem 2rem 0.5rem;
}

.pillsContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.pillsContainer::-webkit-scrollbar {
  display: none;
}

.pillWrapper {
  margin: 0px 6px;
}

.pill {
  display: flex;
  width: max-content;
  padding: 0.25rem 2rem 0.25rem 1.5rem;
  margin-bottom: 1rem;
  background-color: var(--colorBG);
  text-transform: uppercase;
  border-radius: 20px;
  font-family: var(--fontFamilySansSerif);
  font-size: 13px;
  font-weight: 600;
  transition: background-color 250ms;
}

.active {
  background-color: var(--color3);
  color: var(--colorBG);
  transition: background-color 250ms;
}

.plusSignContainer {
  margin-left: 8px;
  position: relative;
}

.plusSign {
  position: absolute;
  bottom: -11px;
  left: 0px;
  transition: transform 250ms;
}

.plusSignRotated {
  transform: rotate(45deg);
  transition: transform 250ms;
}

@media (min-width: 768px) {
  .pill {
    border-width: 2px;
  }
}
