.carousel {
  overflow: hidden;
}

.header {
  margin-bottom: 0.3rem;
}

.scroll {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 var(--pageGutter);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.clip {
  flex: 0 0 calc(75% + (var(--pageGutter) * 2));
  max-width: 340px;
  padding-right: var(--pageGutter);
}

@media (min-width: 1025px) {
  .scroll {
    justify-content: center;
  }
  .header {
    margin-bottom: -5rem;
  }
}
