.favoriteContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.75rem;
  box-shadow: var(--subtleBoxShadow);
}

.topicIcon {
  border-radius: 50%;
  max-width: 15rem;
}

.topicTitle {
  margin-top: 0.2rem;
  max-width: 90%;
  font-family: var(--fontFamilySerif);
  font-size: 0.75rem;
  text-align: center;
}
