.clip {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  border: none;
  display: flex;
  flex: 0 0 280px;
  flex-direction: column;
  position: relative;
  z-index: var(--zIndexBase);
}

.imageHolder {
  background: var(--colorBGA);
  height: 0;
  padding-top: 56%;
  position: relative;
  z-index: var(--zIndexBehind);
}

.image img {
  bottom: 0;
  left: 0;
  height: 100%;
  /* mix-blend-mode: luminosity; */
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.title {
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  display: flex;
  font-family: var(--fontFamilySerif);
  font-size: 1.1rem;
  justify-content: center;
  left: 0;
  letter-spacing: 0.05rem;
  line-height: 1.1;
  margin: auto;
  padding: 0 1.5rem;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(0);
  right: 0;
}

.full {
  flex: 1 1 350px;
  padding: 0;
  margin: 0 0 1.5rem;
}

.full .title {
  right: 0;
}

@media (min-width: 768px) {
  .clip {
    flex: 0 0 350px;
  }

  .full {
    flex: 1 1 calc(50% - 1.5rem);
    margin: 0.75rem;
  }
}

@media (min-width: 1024px) {
  .full {
    flex: 1 1 calc(33.333% - 2rem);
    margin: 1rem;
  }
}
